<template>
  <v-card-text>
    <v-radio-group
      v-model="flag"
      class="mt-0 bt-0"
    >
      <v-radio
        value="ok"
        color="green"
      >
        <template #label>
          <div><strong class="green--text">Great, we can use the data</strong></div>
        </template>
      </v-radio>
      <v-radio
        value="warn"
        color="amber"
      >
        <template #label>
          <div><strong class="amber--text">OK, but could be better (see comment)</strong></div>
        </template>
      </v-radio>
      <v-radio
        value="fail"
        color="red"
      >
        <template #label>
          <div><strong class="red--text">Complete fail, we need to repeat this</strong></div>
        </template>
      </v-radio>
    </v-radio-group>

    <v-text-field
      v-model="comment"
      label="Comment"
    />

    <v-card-actions class="pa-0">
      <v-btn
        depressed
        style="width: 100%;"
        :loading="saving || loading"
        @click="saveflag()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card-text>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import { EventBus } from '../scripts/event_bus'

export default {
  name: 'SaveFlag',
  components: {

  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      flag: null,
      comment: null,
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  watch: {
    data: {
      handler: function (newValue) {
        this.updateData()
      },
      deep: true
    }
  },
  mounted () {
    //      let gnuplotScript = document.createElement('script')
    //      gnuplotScript.setAttribute('src', 'https://cmsdb.darkcosmos.org/gnuplot.js/addcss.js')
    //      document.head.appendChild(gnuplotScript)

    this.updateData()
  },
  methods: {
    updateData: function () {
      this.flag = this.data.flag
      this.comment = this.data.comment
    },
    saveflag: function () {
      const self = this
      self.saving = true
      axios.post('https://cmsdb.darkcosmos.org/axios/saveflag.php?apikey=' + this.userData.api_key, {
        flag: this.flag,
        comment: this.comment,
        file: this.data.fileid
      })
        .then(function (response) {
          console.log(response)
          if (response.data.success !== 'true') {
            EventBus.$emit('error', { title: 'something is not right', message: response.data.error })
          } else {
            EventBus.$emit('info', 'all saved')
          }
          self.saving = false
        })
        .catch(function (error) {
          console.log(error)
          EventBus.$emit('error', { title: 'fatal error', message: error })
          self.saving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
